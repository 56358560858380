import { Flex, Heading } from '@chakra-ui/react'
import React from 'react'

export function Header({title, ...rest}) {
  return (
    <Flex 
      h='16' 
      justifyContent="center" 
      alignItems="center"
      boxShadow='lg'
      {...rest}>
        <Heading size="lg" color="green.50">{title}</Heading>
    </Flex>
  )
}
