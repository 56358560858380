import { Flex } from "@chakra-ui/react";
import { Header } from "./components/header/Header";
import { Mumbo } from "./components/mumbo/Mumbo";

function App() {
  return (
    <Flex flexDir="column">
      <Header bg="green.600" title="The Mumbos"></Header>
      <Flex justifyContent="center" py="12">
        <Mumbo src="/mumbo1.jpeg" altSrc="/mumbo.gif" name="Big Mumbo" />
      </Flex>
    </Flex>
  );
}

export default App;
