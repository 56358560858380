import { Card, CardHeader, Heading, Image } from '@chakra-ui/react'
import React from 'react'

export function Mumbo({src, name, altSrc}) {

    const [selectedSrc, setSelectedSrc] = React.useState(src);

    const onClick = React.useCallback(() => {
        var s = src;
        if (selectedSrc === src && altSrc)
            s = altSrc
        setSelectedSrc(s)
    }, [src, altSrc, selectedSrc]);

  return (
    <Card flexDir="column" bg="white" w="max-content" p="10px" borderRadius="xl" alignItems="center">
        <CardHeader>
            <Heading size="md">{name}</Heading>
        </CardHeader>
        <Image cursor="pointer" boxSize="400px" borderRadius="xl" src={selectedSrc} onClick={onClick}></Image>
    </Card>
  )
}
